@import "./typography";
@import "./vars";

.inputfield-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: left;
  flex-direction: column;

  input {
    width: 85%;
  }
}

.input-label {
  @extend .parrafo;
  margin-bottom: 0.3rem;
  color: $gris5;
}
