@import "./vars";
@import "./mixings";

.card-list {
  li {
    @include card;
    margin-bottom: 1rem;
    padding: 0.5rem;
    background-color: $blanco;
    color: $negro;
    display: grid;

    grid-template-columns: 0.2fr 1fr 0.2fr;
    grid-template-rows: 100%;

    grid-template-areas: "inicial info solicitud";

    .titulo {
      margin-left: 0;
      font-size: 1rem;
      font-weight: bolder;
    }

    .parrafo {
      font-size: 0.75rem;
    }
  }
}

.inicial-icon-container {
  grid-area: inicial;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inicial-icon {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: $azulMiFortaleza;
  font-size: fontSize("H1");
  color: $blanco;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-cliente-container {
  grid-area: info;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-cliente {
  grid-area: info-grupo;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.label-estado-cliente-aprobado {
  padding: 0.3rem 1rem;
  border-radius: 1rem;
  color: $blanco !important;
  background-color: $verde;
}

.label-estado-cliente-validar {
  padding: 0.3rem 1rem;
  border-radius: 1rem;
  color: $blanco !important;
  background-color: $azulMiFortaleza;
}

.label-estado-cliente-rechazado {
  padding: 0.3rem 1rem;
  border-radius: 1rem;
  color: $blanco !important;
  background-color: $rojo;
}

.label-estado-cliente-aval {
  padding: 0.3rem 1rem;
  border-radius: 1rem;
  color: $blanco !important;
  background-color: $naranja;
}

.label-estado-cliente-lista-negra {
  padding: 0.3rem 1rem;
  border-radius: 1rem;
  color: $blanco !important;
  background-color: $negro;
}

.label-estado-visitar-cliente {
  padding: 0.3rem 1rem;
  border-radius: 1rem;
  color: $blanco !important;
  background-color: $morado;
}

.solicitud-icon-container {
  grid-area: solicitud;
  display: flex;
  justify-content: center;
  align-items: center;
}

.solicitud-icon {
  max-width: 2rem;
}
