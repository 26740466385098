@import "./mediaQueries";
@import "./vars";
@import "./themes";

.header {
  font-size: fontSize("Header-Mobile");
  font-weight: fontWeight("Bold");
  font-family: "Montserrat", serif;
  line-height: 1;
  color: $negro;

  @include desktopView {
    font-size: fontSize("Header");
  }

  @include darkTheme {
    color: $gris10;
  }
}

.titulo {
  font-size: fontSize("H1");
  font-weight: fontWeight("Bold");
  font-family: "Montserrat", serif;
  line-height: $line-height-titles;
  color: $negro;

  @include darkTheme {
    color: $gris10;
  }
}

.subtitulo {
  font-size: fontSize("H2");
  font-weight: fontWeight("Normal");
  font-family: "Montserrat", serif;
  line-height: $line-height-titles;
  color: $negro;

  @include darkTheme {
    color: $gris10;
  }
}

.encabezado {
  font-size: fontSize("H3");
  font-weight: fontWeight("Bold");
  font-family: "Montserrat", serif;
  line-height: $line-height-titles;
  color: $negro;

  @include darkTheme {
    color: $gris10;
  }
}

.parrafo {
  font-size: fontSize("P");
  font-weight: fontWeight("Light");
  font-family: Arial, Helvetica, sans-serif;
  line-height: $line-height;
  color: $negro;

  @include darkTheme {
    color: lighten($fondoDarkMode, 45%);
  }
}
