@import "./mediaQueries";
@import "./typography";
@import "./buttons";
@import "./themes";

.top-bar-container {
  position: fixed;
  top: 0;
  z-index: 1;
  padding: 0rem 3rem;
  margin: 0;

  width: calc(100vw - 5rem);
  height: 10vh;

  background-color: $blanco;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between; //vertical
  align-items: center; //horizontal sin sobre pasar el ancho del contenedor los items

  @include phoneLandscapeView {
    height: 20vh;
  }

  @include desktopView {
    height: 10vh;
    margin: 0;
  }

  @include darkTheme {
    background-color: $fondoDarkMode;
  }

  @include printed {
    display: none;
  }
}

.top-desktop-nav {
  display: none;
  width: 7vw;

  ul {
    list-style-type: none;
    overflow: hidden;
  }

  li {
    float: left;
    margin-right: 7%;
  }

  li > a {
    @extend .nav-link;
  }

  @include desktopView {
    display: block;
  }
}

.divider {
  a:last-child {
    color: $negro;
  }

  a:last-child:hover {
    color: $azulMiFortaleza;
  }

  @include darkTheme {
    a:last-child {
      color: lighten($fondoDarkMode, 45%);
    }

    a:last-child:hover {
      color: $azulMiFortaleza;
    }
  }
}

.divider::before {
  content: " ";
  background-color: $azulMiFortaleza;
  width: 1px;
  height: 1.5rem;
  position: absolute;
  margin-left: -1rem;
}

.logotipo-topbar {
  max-width: 23vw;

  @include phoneLandscapeView {
    max-width: 12vw;
  }

  @include desktopView {
    max-width: 7vw;
  }
}
