@import "../sass/resets.scss";
@import "../sass/mediaQueries";
@import "../sass/themes";
@import "../sass/typography";
@import "../sass/buttons";

.pagare {
  margin-top: 10vh;
  height: 90vh;
  width: 90vw;
  margin-left: 5vw;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10rem auto auto auto 3vh;
  grid-gap: 2rem;

  grid-template-areas:
    "cabecera-pagare"
    "contenido"
    "cliente"
    "aval"
    "bottom-space";

  @include printed {
    margin-top: 0vh;
    grid-gap: 1rem;
    p {
      font-size: 11px;
    }
  }
}

.cabecera-pagare {
  grid-area: cabecera-pagare;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: "left center right";

  .logotipo {
    grid-area: left;
  }

  .titulo {
    grid-area: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.contenido {
  grid-area: contenido;
  text-align: justify;

  p {
    margin: 0px 0px 10px 0px;
  }

  strong {
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none;
  }
}

.cliente {
  grid-area: cliente;

  .cabecera {
    display: flex;
    flex-flow: column wrap;
    justify-content: center; //vertical
    align-items: center;
    margin: 0rem 0rem 1rem 0rem;
  }
}

.aval {
  grid-area: aval;
  .cabecera {
    display: flex;
    flex-flow: column wrap;
    justify-content: center; //vertical
    align-items: center;
    margin: 0rem 0rem 1rem 0rem;
  }
}

.fpagare1 {
  float: right;
  margin-top: 10px;
  margin-right: 18rem;
  &::after {
    content: " ";
    background-color: $negro;
    width: 18rem;
    height: 0.1rem;
    position: absolute;
    margin: 1.3rem 0.3rem;
  }
}

.fpagare2 {
  float: right;
  margin-top: 10px;
  margin-right: 19rem;
  &::after {
    content: " ";
    background-color: $negro;
    width: 19rem;
    height: 0.1rem;
    position: absolute;
    margin: 1.3rem 0.3rem;
  }
}

.bottom-space {
  grid-area: bottom-space;
}
