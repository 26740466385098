@import "./vars";

.multiple-btn {
  float: left;
  overflow: hidden;

  .dropbtn {
    font-size: fontSize("P");
    border: none;
    color: $negro;
    padding: 0.7rem 2rem;
    background-color: $blanco;
    margin: 0;
  }

  &:hover .dropbtn {
    background-color: transparent;
  }

  img {
    width: 3rem;
  }
}

.multiple-btn-content {
  display: none;
  position: absolute;
  margin-left: 25px;
  background-color: $blanco;
  min-width: 175px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  a {
    float: none;
    color: $negro;
    padding: 12px 16px;
    font-size: fontSize("H2");
    text-decoration: none;
    display: block;
    text-align: left;

    &:hover {
      background-color: $gris15;
    }
  }
}

.multiple-btn:hover .multiple-btn-content {
  display: block;
  margin: -12rem -10rem;
}
