@import "../sass/resets.scss";
@import "../sass/mediaQueries";
@import "../sass/themes";
@import "../sass/typography";
@import "../sass/buttons";

.solicitud {
  margin-top: 10vh;
  height: 90vh;
  width: 90vw;
  margin-left: 5vw;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 15rem auto auto 3vh;
  grid-gap: 0.5rem;

  grid-template-areas:
    "cabecera"
    "contenido"
    "leyenda"
    "bottom-space";

  @include printed {
    grid-template-rows: 11rem auto auto auto 3vh;
    margin-top: 0vh;
    p {
      font-size: 11px;
    }
  }
}

.cabecera {
  grid-area: cabecera;
}

.logo-folios {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between; //vertical
  align-items: top; //horizontal sin sobre pasar el ancho del contenedor los items
}

.folios {
  margin-top: 2rem;

  p {
    text-align: right;
    margin-bottom: 0.3rem;
  }
}

.logotipo {
  height: 7rem;
  width: 15rem;
}

.marca-agua {
  color: rgb(210, 227, 243);
  position: absolute;

  p {
    margin: 35rem 12rem;
    font-size: 7rem;
    font-weight: bolder;
    z-index: 1;

    transform: rotate(-45deg);
    opacity: 0.5;
  }

  @include printed {
    position: fixed;

    p {
      margin: 35rem 0rem;
    }
  }
}

.contenido {
  grid-area: contenido;

  .titulo-section {
    background-color: #d6d6d6;
    border: 1pt solid #000;
    text-align: center;
    font-weight: bold;
    height: 1.5rem;
    line-height: 1.5rem;

    p {
      font-size: 0.8rem;
      line-height: 1.5rem;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .subtitulo-section {
    text-align: center;
    text-decoration: underline;
    margin: 0.5rem 0rem;
  }

  .prospecto-section {
    border: 1pt solid #000;
    border-top: none;
    border-bottom: none;
    padding: 0.5rem;
    padding-bottom: 0.2rem;

    display: grid;
    grid-template-columns: 1.3fr 0.85fr 0.85fr;
    grid-gap: 0rem;
    grid-column-gap: 2rem;

    grid-template-rows: auto auto auto auto auto auto auto auto auto;

    grid-template-areas:
      "nombre nombre nombre"
      "genero fecha-nac edad"
      "actividad antiguedad tel"
      "curp ine tel-fijo"
      "correo persona-recados tel-recados"
      "subtitulo subtitulo subtitulo"
      "edo-civil posicion-hogar posicion-hogar"
      "estado pais nacionalidad"
      "direccion direccion direccion";

    @include printed {
      p > strong {
        font-size: 9pt;
      }
    }

    .campo-titulo {
      @extend .parrafo;
      font-size: 7pt;
      margin-bottom: 0.1rem;
    }

    .nombre {
      grid-area: nombre;
    }

    .actividad {
      grid-area: actividad;
    }

    .antiguedad {
      grid-area: antiguedad;
    }

    .ine {
      grid-area: ine;
    }

    .curp {
      grid-area: curp;
    }

    .tel {
      grid-area: tel;
    }

    .direccion {
      grid-area: direccion;
    }

    .subtitulo {
      grid-area: subtitulo;
    }

    .genero {
      grid-area: genero;
    }

    .fecha-nac {
      grid-area: fecha-nac;
    }

    .edad {
      grid-area: edad;
    }

    .edo-civil {
      grid-area: edo-civil;
    }

    .posicion-hogar {
      grid-area: posicion-hogar;
    }

    .estado {
      grid-area: estado;
    }

    .pais {
      grid-area: pais;
    }

    .nacionalidad {
      grid-area: nacionalidad;
    }

    .contacto {
      grid-area: contacto;
    }

    .correo {
      grid-area: correo;
    }

    .tel-fijo {
      grid-area: tel-fijo;
    }

    .persona-recados {
      grid-area: persona-recados;
    }

    .tel-recados {
      grid-area: tel-recados;
    }
  }
}

.add-border-bottom {
  border-bottom: 1pt solid #000;
}

.sin-conyuge-section {
  border: 1pt solid #000;
  border-top: none;
  border-bottom: none;
  padding: 3.7rem 1rem;

  .sin-conyuge-label {
    grid-area: nombre;
    font-size: 1rem;
    font-weight: bolder;
    text-align: center;
  }
}

.aval-section {
  border: 1pt solid #000;
  border-top: none;
  border-bottom: none;
  padding: 0.5rem;
  padding-bottom: 0.2rem;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0rem;
  grid-column-gap: 2rem;

  grid-template-rows: auto auto auto auto;

  grid-template-areas:
    "nombre nombre nombre"
    "actividad antiguedad tel"
    "curp ine ine"
    "direccion direccion direccion";

  @include printed {
    p > strong {
      font-size: 9pt;
    }
  }

  .campo-titulo {
    @extend .parrafo;
    font-size: 7pt;
    margin-bottom: 0.1rem;
  }

  .nombre {
    grid-area: nombre;
  }

  .actividad {
    grid-area: actividad;
  }

  .tel {
    grid-area: tel;
  }

  .curp {
    grid-area: curp;
  }

  .ine {
    grid-area: ine;
  }

  .direccion {
    grid-area: direccion;
  }
}

.conyuge-section {
  border: 1pt solid #000;
  border-top: none;
  border-bottom: none;
  padding: 0.5rem;
  padding-bottom: 0.2rem;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0rem;
  grid-column-gap: 2rem;

  grid-template-rows: auto auto auto auto auto;

  grid-template-areas:
    "nombre nombre nombre"
    "tel fecha-nac edad"
    "ocupacion lugar-trabajo lugar-trabajo";

  @include printed {
    p > strong {
      font-size: 9pt;
    }
  }

  .campo-titulo {
    @extend .parrafo;
    font-size: 7pt;
    margin-bottom: 0.1rem;
  }

  .nombre {
    grid-area: nombre;
  }

  .tel {
    grid-area: tel;
  }

  .fecha-nac {
    grid-area: fecha-nac;
  }

  .edad {
    grid-area: edad;
  }

  .ocupacion {
    grid-area: ocupacion;
  }

  .lugar-trabajo {
    grid-area: lugar-trabajo;
  }
}

.familiar-section {
  border: 1pt solid #000;
  border-top: none;
  padding: 0.5rem;
  padding-bottom: 0.2rem;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0rem;
  grid-column-gap: 2rem;

  grid-template-rows: auto auto auto;

  grid-template-areas:
    "nombre nombre nombre"
    "parentesco parentesco tel"
    "direccion direccion direccion";

  @include printed {
    p > strong {
      font-size: 9pt;
    }
  }

  .campo-titulo {
    @extend .parrafo;
    font-size: 7pt;
    margin-bottom: 0.1rem;
  }

  .nombre {
    grid-area: nombre;
  }

  .tel {
    grid-area: tel;
  }

  .parentesco {
    grid-area: parentesco;
  }

  .direccion {
    grid-area: direccion;
  }
}

.conocido-section {
  border: 1pt solid #000;
  border-top: none;
  border-bottom: none;

  @extend .familiar-section;
}

.empresa-section {
  border: 1pt solid #000;
  border-top: none;
  border-bottom: none;
  padding: 0.5rem;
  padding-bottom: 0.2rem;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0rem;
  grid-column-gap: 2rem;

  grid-template-rows: auto auto auto auto auto;

  grid-template-areas:
    "nombre nombre nombre"
    "puesto salario salario"
    "fecha-inicio antiguedad tel"
    "jefe jefe-tel jefe-tel"
    "direccion direccion direccion";

  @include printed {
    p > strong {
      font-size: 9pt;
    }
  }

  .campo-titulo {
    @extend .parrafo;
    font-size: 7pt;
    margin-bottom: 0.1rem;
  }

  .nombre {
    grid-area: nombre;
  }

  .fecha-inicio {
    grid-area: fecha-inicio;
  }

  .antiguedad {
    grid-area: antiguedad;
  }

  .tel {
    grid-area: tel;
  }

  .jefe {
    grid-area: jefe;
  }

  .jefe-tel {
    grid-area: jefe-tel;
  }

  .puesto {
    grid-area: puesto;
  }

  .salario {
    grid-area: salario;
  }

  .direccion {
    grid-area: direccion;
  }
}

.domicilio-section {
  border: 1pt solid #000;
  border-top: none;
  border-bottom: none;
  padding: 0.5rem;
  padding-bottom: 0.2rem;

  display: grid;
  grid-template-columns: 0.5fr 0.7fr 1fr;
  grid-gap: 0rem;
  grid-column-gap: 2rem;

  grid-template-rows: auto;

  grid-template-areas: "croquis calles campos";

  @include printed {
    p > strong {
      font-size: 9pt;
    }
  }

  .campo-titulo {
    @extend .parrafo;
    font-size: 7pt;
    margin-bottom: 0.1rem;
  }

  .leyenda {
    text-align: center;
  }

  .croquis-img {
    height: 8rem;
    width: 8rem;
    margin: 1rem;
  }

  .calles {
    grid-area: calles;
  }

  .croquis {
    grid-area: croquis;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  .campos {
    grid-area: campos;
  }
}

.privacidad-section {
  border: 1pt solid #000;
  border-top: none;
  border-bottom: none;
  padding: 0.5rem;
  padding-bottom: 0rem;

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0rem;
  grid-column-gap: 2rem;

  grid-template-rows: auto;

  grid-template-areas: "aviso";

  .aviso {
    grid-area: aviso;
    font-size: 7pt;
  }

  @include printed {
    .aviso {
      grid-area: aviso;
      font-size: 7pt;
    }
  }
}

.autorizacion-section {
  border: 1pt solid #000;
  border-top: none;
  padding: 0.5rem;
  padding-bottom: 0rem;

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0rem;
  grid-column-gap: 2rem;

  grid-template-rows: auto;

  grid-template-areas:
    "autorizacion"
    "firmas";

  .legales > p {
    font-size: 7pt;
    margin-bottom: 0.5rem;
  }

  .autorizacion {
    grid-area: autorizacion;
  }

  .firmas {
    display: grid;
    grid-template-columns: 0.9fr 1.2fr 0.9fr;
    grid-gap: 0rem;
    grid-column-gap: 2rem;

    grid-template-rows: auto;

    grid-template-areas: "apoderado prospecto sello";

    strong {
      font-size: 9pt;
    }

    .f4 {
      &::before {
        content: " ";
        background-color: $negro;
      }
      text-align: center;
    }

    .apoderado {
      grid-area: apoderado;
      display: flex;
      flex-flow: column;

      align-self: center;
      align-items: center;

      .f4 {
        &::before {
          width: 18rem;
          height: 0.05rem;
          position: absolute;
          margin: -0.3rem -5rem;
        }
        text-align: center;
      }
    }

    .prospecto {
      grid-area: prospecto;

      display: flex;
      flex-flow: column;

      align-self: center;
      align-items: center;

      .f4 {
        &::before {
          width: 23rem;
          height: 0.05rem;
          position: absolute;
          margin: -0.3rem -1rem;
        }
        text-align: center;
      }
    }

    .sello {
      grid-area: sello;

      display: flex;
      flex-flow: column;

      align-self: center;
      align-items: center;

      .f4 {
        &::before {
          width: 19rem;
          height: 0.05rem;
          position: absolute;
          margin: -0.3rem -5rem;
        }
        text-align: center;
      }
    }

    @include printed {
      strong {
        font-size: 6pt;
      }

      .apoderado {
        .f4 {
          &::before {
            width: 12.5rem;
            height: 0.05rem;
            position: absolute;
            margin: -0.3rem -3rem;
          }
          text-align: center;
        }
      }

      .prospecto {
        .f4 {
          &::before {
            width: 17rem;
            height: 0.05rem;
            position: absolute;
            margin: -0.3rem 0rem;
          }
          text-align: center;
        }
      }

      .sello {
        .f4 {
          &::before {
            width: 11.5rem;
            height: 0.05rem;
            position: absolute;
            margin: -0.3rem -2rem;
          }
          text-align: center;
        }
      }
    }
  }

  @include printed {
    .legales > p {
      font-size: 7pt;
      margin-bottom: 0.3rem;
    }
  }
}

.bottom-leyenda {
  margin-top: 15rem;
}

.bottom-space {
  grid-area: bottom-space;
}
