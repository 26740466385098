@import "./vars";

@mixin card {
  border: 0.5px solid $gris15;
  border-radius: 1rem;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  background-color: $blanco;
  padding: 0.5rem;
}

@mixin label-estado-integrante-grupo {
  font-size: 0.7rem;
  margin-left: 1rem;
  padding: 0.3rem 0.7rem;
  border-radius: 1rem;
}
