$azulMiFortaleza: #2885ff;
$amarilloMiFortaleza: #ebd238;
$naranja: #f0931a;
$rojo: #cf2323;
$gris0: #262626;
$gris1: #484848;
$gris5: lighten($gris0, 25%);
$gris10: lighten($gris0, 50%);
$gris11: lighten($gris0, 60%);
$gris15: lighten($gris0, 75%);
$blanco: #fff;
$negro: #000;
$magenta: #de1669;
$verde: #37b851;
$morado: #5233a7;
$fondoDarkMode: #1c202e;
$primaryDarkMode: lighten($fondoDarkMode, 15%);
$primaryDark: #15a099;
$lightPurple: #445383;

$fontSizes: (
  "Header": 2.5rem,
  "Header-Mobile": 3.5rem,
  "H1": 1.75rem,
  "H2": 1.2rem,
  "H3": 1.1rem,
  "P": 1rem,
);

$fontWeights: (
  "Bold": 900,
  "Normal": 500,
  "Light": 100,
);

$line-height: 1.6;
$line-height-titles: 1.2;

@function fontWeight($weight) {
  @return map-get($fontWeights, $weight);
}

@function fontSize($size) {
  @return map-get($fontSizes, $size);
} ;
