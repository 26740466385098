@import "./typography";
@import "./vars";
@import "./mixings";

.page-container {
  width: 100vw;
  height: 85vh;
  margin-top: 15vh;
  display: grid;

  grid-template-columns: 1.6fr 1fr;
  grid-template-rows: 20% 80%; //10% 45% 45% 100% 100% 100% 100% 75% 20%;

  grid-template-areas:
    "top-header solicitud-info"
    "cards solicitud-info";
}

.cards {
  grid-area: cards;
  height: 95%;
  overflow: scroll;
}

.cards-container {
  margin: 1rem 2.5rem;
}

.top-header {
  grid-area: top-header;
  margin: 0rem 3rem;

  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: 30% 70%; //10% 45% 45% 100% 100% 100% 100% 75% 20%;

  grid-template-areas:
    "title"
    "top-header-content";
}

.top-header-content {
  grid-area: top-header-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.solicitud-info {
  grid-area: solicitud-info;
  display: flex;
  justify-content: center;
  align-items: center;
}

.solicitud-info-container {
  margin: 1rem 3rem;
  width: 100%;
  height: 90%;
  @include card;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 45% 7% 35% 13%; //10% 45% 45% 100% 100% 100% 100% 75% 20%;

  grid-template-areas:
    "ine-img"
    "lbl"
    "campos"
    "btns";
}

.ine-container {
  grid-area: ine-img;
  img {
    width: 100%;
    height: 100%;
    border-radius: 1rem 1rem 0rem 0rem;
  }
}

.label-container {
  grid-area: lbl;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  button {
    outline: none;
    display: block;
    padding: 0;

    overflow: hidden;

    border-width: 0;
  }

  .informacion {
    margin-left: 1rem;
  }

  .aval {
    margin-left: 0.1rem;
  }

  button.activo {
    padding: 0.3rem 1rem;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
    background-color: $gris1;
    color: $blanco;

    &:hover {
      background-color: darken($gris1, 10%);
    }
  }

  button.desactivado {
    padding: 0.3rem 1rem;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
    background-color: $gris10;
    color: $blanco;

    &:hover {
      background-color: darken($gris10, 10%);
    }
  }
}

.campos-container {
  grid-area: campos;
  padding: 0.3rem 1rem;
  overflow: scroll;

  & > * {
    margin-bottom: 0.7rem;
  }
}

.btn-fixed {
  position: fixed;
  margin-top: 11rem;
  margin-left: 23rem;
}

.btns-container {
  grid-area: btns;
  margin: 0rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
