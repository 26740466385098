@import "./buttons";

.modal {
  width: 70%;
  height: 70%;
  position: absolute;
  top: 5%;
  left: 15%;

  background: rgba(0, 0, 0, 0.7);

  z-index: 2;
}

.modal-main {
  background: transparent;
  width: auto;
  height: auto;
  display: flex;
  z-index: 10;

  flex-flow: column;

  button {
    @extend .btn;
    position: fixed;
    top: 7vh;
    left: 87vw;
    font-size: 3rem;
    color: $blanco;
    background-color: $rojo;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      color: $rojo;
      background-color: darken($blanco, 7%);
    }
  }
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}
