@import "./vars";
@import "./typography";
@import "./mixings";

.grupo__btn {
  width: 100%;
  @include card;
  margin: 0.5rem 0rem;
  padding: 0.5rem;
  background-color: $blanco;
  color: $negro;
  display: grid;

  grid-template-columns: 0.2fr 1fr 0.2fr;
  grid-template-rows: 100%;

  grid-template-areas: "inicial info-grupo icons";

  .titulo {
    margin-left: 0;
    font-size: 1rem;
    font-weight: bolder;
  }

  .parrafo {
    font-size: 0.75rem;
  }

  &:focus {
    outline: none;
  }
}

.inicial-grupo-container {
  grid-area: inicial;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-grupo-container {
  grid-area: info-grupo;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
}

.icons-grupo-container {
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;

  & :nth-child(2) {
    margin-left: 1rem;
  }
}

.label-grupo-completo {
  padding: 0.3rem 1rem;
  border-radius: 1rem;
  color: $blanco;
  background-color: $verde;
}

.label-grupo-incompleto {
  padding: 0.3rem 1rem;
  border-radius: 1rem;
  color: $blanco;
  background-color: $rojo;
}

.icon-desplegar-container {
  grid-area: icons;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-desplegar {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  color: $blanco;
  font-size: 10px;
  background-color: $gris1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion__content {
  padding: 0 20px;
  opacity: 0;
  transition: all 0.3s;
  height: 0;
  margin: 0;
  margin-top: 0px;
  border: 1px solid #f1f1f1;
  border-top: 0;
}

.accordion__content.active {
  height: auto;
  opacity: 1;
  padding: 20px;
}

.cliente-aprobado {
  &::after {
    content: "Aprobado";
    @include label-estado-integrante-grupo;
    color: $blanco;
    background-color: $verde;
  }
}

.cliente-pendiente {
  &::after {
    content: "Pendiente";
    @include label-estado-integrante-grupo;
    color: $blanco;
    background-color: $naranja;
  }
}

.cliente-rechazado {
  &::after {
    content: "Rechazado";
    @include label-estado-integrante-grupo;
    color: $blanco;
    background-color: $rojo;
  }
}

.cliente-lista-negra {
  &::after {
    content: "Lista Negra";
    @include label-estado-integrante-grupo;
    color: $blanco;
    background-color: $negro;
  }
}
