@import "../sass/resets.scss";
@import "../sass/mediaQueries";
@import "../sass/themes";
@import "../sass/typography";
@import "../sass/buttons";

.contrato {
  margin-top: 10vh;
  height: 90vh;
  width: 90vw;
  margin-left: 5vw;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto auto auto auto;
  grid-gap: 2rem;

  grid-template-areas:
    "cabecera"
    "cliente"
    "prestamo"
    "legal"
    "firmas"
    "pie-pagina";

  @include printed {
    margin-top: 0vh;
  }
}

.cabecera {
  grid-area: cabecera;
}

.logo-folios {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between; //vertical
  align-items: top; //horizontal sin sobre pasar el ancho del contenedor los items
}

.folios {
  margin-top: 2rem;

  p {
    text-align: right;
  }
}

.logotipo {
  height: 10rem;
  width: 17rem;
}

.prestamo {
  grid-area: prestamo;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto auto auto;

  grid-template-areas:
    "sub-info-p sub-info-p sub-info-p sub-info-p"
    "r2-1 r2-2 r2-3 r2-4"
    "r3-1 r3-2 r3-3 r3-4"
    "r4-1 r4-2 r4-3 r4-3";
}

#tipoPrestamo {
  text-decoration: underline;
}

.subt-info {
  grid-area: sub-info-p;
  margin: 2rem 0rem;
  text-decoration: underline;
}

@mixin info-prestamo() {
  border: 1px solid $negro;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include printed {
    padding: 2px;
  }
}

@mixin content-cell-prestamo() {
  display: flex;

  flex-flow: column;
  align-items: center;
  text-align: center;
}

.monto-prestamo {
  grid-area: r2-2;
  @include info-prestamo();
  div {
    @include content-cell-prestamo();
  }
}

.cargo-servicio {
  grid-area: r2-3;
  @include info-prestamo();
  div {
    @include content-cell-prestamo();
  }
}

.total-pagar {
  grid-area: r2-4;
  @include info-prestamo();
  div {
    @include content-cell-prestamo();
  }
}

.cat {
  grid-area: r2-1;
  @include info-prestamo();
  div {
    @include content-cell-prestamo();
  }
}

.primer-abono {
  grid-area: r3-1;
  @include info-prestamo();
  div {
    @include content-cell-prestamo();
  }
}

.abonos-subsecuentes {
  grid-area: r3-2;
  @include info-prestamo();
  div {
    @include content-cell-prestamo();
  }
}

.dia-pago {
  grid-area: r3-3;
  @include info-prestamo();
  div {
    @include content-cell-prestamo();
  }
}

.plazo {
  grid-area: r3-4;
  @include info-prestamo();

  div {
    @include content-cell-prestamo();
  }
}

.interes-moratorio {
  grid-area: r4-1;
  @include info-prestamo();
  div {
    @include content-cell-prestamo();
  }
}

.interes-anual {
  grid-area: r4-2;
  @include info-prestamo();
  div {
    @include content-cell-prestamo();
  }
}

.promotor {
  grid-area: r4-3;
  @include info-prestamo();
  div {
    @include content-cell-prestamo();
  }
}

.cliente {
  grid-area: cliente;
  text-align: justify;
}

.info-prestamo {
  margin: 3rem 2rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between; //vertical
  align-items: top; //horizontal sin sobre pasar el ancho del contenedor los items
}

.ip-2 {
  margin-top: 4rem;
  margin-bottom: 3rem;
}

.col-prestamo {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between; //vertical
  align-items: top;
  text-align: center;

  a {
    margin: 0rem 4rem;
  }
}

.beneficiario {
  margin-bottom: 1rem;
  text-align: justify;
  strong {
    font-size: larger;
  }
}

.p-contrato {
  margin-bottom: 1rem;
  text-align: justify;
}

.clausulas {
  @extend .parrafo;
  list-style-type: decimal;
  margin-left: 3rem;

  ol {
    li {
      margin: 1rem 1.5rem 1rem 3rem;
      list-style: lower-latin;
      text-align: justify;
    }
  }
}

.top-firma {
  margin: 5rem 3rem 0rem 3rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between; //vertical
  align-items: top; //horizontal sin sobre pasar el ancho del contenedor los items
}

.mid-firma {
  margin: 7rem 3rem 0rem 3rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center; //vertical
  align-items: center; //horizontal sin sobre pasar el ancho del contenedor los items
}

.bottom-firma {
  margin: 7rem 3rem 3rem 3rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between; //vertical
  align-items: center; //horizontal sin sobre pasar el ancho del contenedor los items
}

.firma-container {
  text-align: center;
}

.f1 {
  &::before {
    content: " ";
    background-color: $negro;
    width: 18rem;
    height: 0.1rem;
    position: absolute;
    margin: -0.3rem 0rem;
  }
}

.f2 {
  &::before {
    content: " ";
    background-color: $negro;
    width: 17rem;
    height: 0.1rem;
    position: absolute;
    margin: -0.3rem 0rem;
  }
}

.f3 {
  &::before {
    content: " ";
    background-color: $negro;
    width: 20rem;
    height: 0.1rem;
    position: absolute;
    margin: -0.3rem 0rem;
  }
}

.f4 {
  &::before {
    content: " ";
    background-color: $negro;
    width: 20rem;
    height: 0.1rem;
    position: absolute;
    margin: -0.3rem -5rem;
  }
  text-align: center;
}

.f5 {
  &::before {
    content: " ";
    background-color: $negro;
    width: 12rem;
    height: 0.1rem;
    position: absolute;
    margin: -0.3rem 0rem;
  }
}

.pie-pagina {
  grid-area: pie-pagina;
  .parrafo {
    font-size: 12px;
    margin-bottom: 3vh;
  }
}
