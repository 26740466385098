@import "./vars";

.dropdown {
  float: left;
  overflow: hidden;

  .dropbtn {
    font-size: fontSize("P");
    border: 0.5px solid $gris15;
    border-radius: 0.7rem;
    color: $negro;
    padding: 0.7rem 2rem;
    background-color: $blanco;
    margin: 0;
  }

  &:hover .dropbtn {
    background-color: transparent;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    margin-left: 25px;
    background-color: $blanco;
    min-width: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    button {
      float: none;
      color: $negro;
      background-color: $blanco;
      border: none;
      width: 100%;
      padding: 12px 32px 10px 12px;
      font-size: fontSize("H2");
      text-decoration: none;
      display: block;
      text-align: left;

      &:hover {
        background-color: $gris15;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.icon-drop {
  margin-left: 10px;
}

.dropdown:hover .dropdown-content {
  display: block;
}
