@import "./vars";
@import "./themes";

.btn {
  position: relative;
  cursor: pointer;

  display: block;
  padding: 0;

  overflow: hidden;

  border-width: 0;
  outline: none;
  border-radius: 1vh;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);

  background-color: $gris10;
  color: #ecf0f1;

  transition: background-color 0.3s;

  &:hover {
    background-color: darken($gris10, 7%);
  }

  &:before {
    content: "";

    position: absolute;
    top: 50%;
    left: 50%;

    display: block;
    width: 0;
    padding-top: 0;

    border-radius: 100%;

    background-color: rgba(236, 240, 241, 0.1);

    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  &:active:before {
    width: 120%;
    padding-top: 120%;

    transition: width 0.2s ease-out, padding-top 0.2s ease-out;
  }

  & > * {
    position: relative;
  }

  & span {
    display: block;
    font-size: fontSize("P");
    padding: 0px 0px;
  }
}

.btn-brdr {
  position: relative;

  display: block;
  padding: 0;

  overflow: hidden;

  border: 0.1vh solid $gris0; /* Green */
  outline: none;
  border-radius: 1vh;
  box-shadow: 0;

  background-color: transparent;
  color: $negro;

  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(236, 240, 241, 0.3);
  }

  &:before {
    content: "";

    position: absolute;
    top: 50%;
    left: 50%;

    display: block;
    width: 0;
    padding-top: 0;

    border-radius: 100%;

    background-color: rgba(236, 240, 241, 0.3);

    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  &:active:before {
    width: 120%;
    padding-top: 120%;

    transition: width 0.2s ease-out, padding-top 0.2s ease-out;
  }

  & > * {
    position: relative;
  }

  & span {
    display: block;
    padding: 1px 10px;
    font-size: fontSize("P");
  }
}

.link {
  font-size: fontSize("P");
  font-weight: fontWeight("Light");
  font-family: "Montserrat", serif;
  line-height: $line-height;
  text-decoration: underline;
  cursor: pointer;

  color: $gris5;

  &:hover {
    color: $azulMiFortaleza;
  }

  @include darkTheme {
    color: lighten($fondoDarkMode, 45%);

    &:hover {
      color: $azulMiFortaleza;
    }
  }
}

.nav-link {
  font-size: fontSize("P");
  font-weight: fontWeight("Light");
  font-family: "Montserrat", serif;
  line-height: $line-height;
  text-decoration: none;
  color: $gris5;
  cursor: pointer;

  &:hover {
    color: $negro;
  }

  @include darkTheme {
    color: lighten($fondoDarkMode, 55%);

    &:hover {
      color: $gris11;
    }
  }
}
