@import "./vars";

.btn-img {
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  padding: 0px !important;

  &:focus {
    outline: none;
  }
}
