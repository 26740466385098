@import "./vars";

.dropdown-menu {
  float: left;
  overflow: hidden;

  .dropbtn {
    font-size: fontSize("H1");
    border: none;
    outline: none;
    color: $negro;
    padding: 1rem 2rem;
    background-color: $blanco;
    margin: 0;
  }

  &:hover .dropbtn {
    background-color: transparent;
  }

  .icon-drop {
    margin-left: 10px;
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  margin-left: 25px;
  background-color: $blanco;
  min-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  a {
    float: none;
    color: $negro;
    padding: 12px 16px;
    font-size: fontSize("H2");
    text-decoration: none;
    display: block;
    text-align: left;

    &:hover {
      background-color: $gris15;
    }
  }
}

.dropdown-menu:hover .dropdown-content {
  display: block;
}
