@import "./typography";

input {
  @extend .parrafo;
}

.searchbar {
  width: 70%;
  padding: 0.7rem;
  border: 0.5px solid $gris15;
  border-radius: 0.7rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
