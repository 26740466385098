@import "./mixings";
@import "./buttons";

.login-container {
  width: 100vw;
  height: 100vh;
  display: grid;

  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100%; //10% 45% 45% 100% 100% 100% 100% 75% 20%;

  grid-template-areas: "logo form";
}

.logo-login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;

  .img-login {
    max-width: 40vw;
    max-height: 25vh;
  }

  h1 {
    margin-top: 2rem;
    font-size: 1rem;
  }
}

.login-form {
  grid-area: form;
  margin: 8.5rem 9rem;
  @include card;
  padding: 2rem;

  h1 {
    margin-bottom: 2rem;
  }

  input {
    width: 93%;
  }

  & > * {
    margin-bottom: 1rem;
  }
}

.btn-entrar {
  @extend .btn;

  width: 100%;
  height: 3rem;
  margin-top: 3rem;
  background-color: $azulMiFortaleza;
  color: #ecf0f1;

  &:hover {
    background-color: darken($azulMiFortaleza, 7%);
  }
}
